<template>
  <!-- 头部组件 -->
  <AppHeader/>
  <!-- 吸顶头部 -->
  <AppHeaderSticky/>
  <!-- 内容容器 -->
  <div class="app-body">
    <!-- 二级路由 -->
    <RouterView/>
  </div>
  <!-- 底部组件 -->
  <AppFooterTop/>
  <AppFooter/>
</template>
<script>
import AppHeader from '@/components/app-header'
import AppFooter from '@/components/app-footer'
import AppHeaderSticky from '@/components/app-header-sticky'
import AppFooterTop from "@/components/app-footer-top.vue";
import {useStore} from 'vuex'

export default {
  name: 'Layout',
  components: {
    AppHeader,
    AppFooter,
    AppHeaderSticky,
    AppFooterTop,
  },
  // 获取下分类数据
  setup() {
    const store = useStore()
    store.dispatch('menu/getList')
  }
}
</script>
<style scoped lang="less">
.app-body {
  min-height: 600px;
  width: 160vh;
  margin: auto;
  background-color: #ffffff;
}
</style>
