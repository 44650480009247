<template>
  <ul class="app-header-nav">
    <li v-for="item in list" :class="{active:item.count && $route.params.id===item.count}" :key="item.count"
        @mousemove="show(item)" @mouseleave="hide(item)">
<!--      <a v-if="route.path==='/'" :style="{'color' : y===0&&route.path==='/'?'white':'black'}" @click="hide(item)" :href="'https://www.jianhangtech.com'+item.path">{{ item.name }}</a>-->
      <RouterLink  :style="{'color' : y===0&&route.path==='/'?'white':'black'}" @click="hide(item)" :to="`${item.path}`">{{ item.name }}</RouterLink>
      <div class="layer" :class="{open:item.open}" :style="{height: compete(item.children.length)+'px !important'}"
           v-if="item.children">
        <ul>
          <li v-for="sub in item.children" :key="sub.count">
            <RouterLink @click="hide(item)" :to="`${sub.path}`">
              <p>{{ sub.name }}</p>
            </RouterLink>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</template>
<script>
import {computed, onBeforeUnmount, onMounted, ref} from 'vue'
import {useStore} from 'vuex'
import {useRoute} from "vue-router";
import {useWindowScroll} from "@vueuse/core";

const compete = (length) => {
  return (Number)(length * 32.5)
}
export default {
  name: 'AppHeaderNav',
  setup() {
    const store = useStore()
    // 拿到vuex中的分类列表
    const list = computed(() => {
      return store.state.menu.list
      // return store.state.category.list
    })
    // 跳转的时候不会关闭二级类目，通过数据来控制
    // 1. vuex每个分类加上open数据
    // 2. vuex提供显示和隐藏函数，修改open数据
    // 3. 组件中使用vuex中的函数，使用时间来绑定，提供一个类名显示隐藏的类名
    const show = (item) => {
      store.commit('menu/show', item.count)
    }

    const hide = (item) => {
      store.commit('menu/hide', item.count)
    }
    const route = useRoute();
    const { y } = useWindowScroll()
    return {list, show, hide, compete, route,y}
  }
}
</script>
<style scoped lang="less">
.app-header-nav {
  width: 60vw;
  display: flex;
  justify-content: space-around;
  padding-left: 40px;
  position: relative;
  z-index: 999;

  > li {
    text-align: center;

    > a {
      font-size: 16px;
      line-height: 32px;
      height: 32px;
      display: inline-block;
    }

    &:hover, &.active {
      > a {
        color: @baseColor;
        border-bottom: 1px solid @baseColor;
      }

      // 显示二级类目
      // > .layer {
      //   height: 132px;
      //   opacity: 1;
      // }
    }
  }
}

.layer {
  width: 100px;
  background-color: #fff;
  position: fixed;
  top: 70px;
  height: 0;
  overflow: hidden;
  opacity: 0;
  box-shadow: 0 0 5px #ccc;
  transition: all .2s .1s;

  &.open {
    opacity: 1;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    li {
      width: 100%;
      text-align: center;

      img {
        width: 60px;
        height: 60px;
      }

      p {
        padding-top: 10px;
      }

      &:hover {
        p {
          color: @baseColor;
        }
      }
    }
  }
}

// 二级类名弹层
//.layer {
//  &.open {
//    height: 132px;
//    opacity: 1;
//  }
//
//  width: 1240px;
//  background-color: #fff;
//  position: absolute;
//  left: -200px;
//  top: 56px;
//  height: 0;
//  overflow: hidden;
//  opacity: 0;
//  box-shadow: 0 0 5px #ccc;
//  transition: all .2s .1s;
//
//  ul {
//    display: flex;
//    flex-wrap: wrap;
//    padding: 0 70px;
//    align-items: center;
//    height: 132px;
//
//    li {
//      width: 110px;
//      text-align: center;
//
//      img {
//        width: 60px;
//        height: 60px;
//      }
//
//      p {
//        padding-top: 10px;
//      }
//
//      &:hover {
//        p {
//          color: @baseColor;
//        }
//      }
//    }
//  }
//}
</style>
