<template xmlns="http://www.w3.org/1999/html">
  <!-- 其它 -->
  <div class="extra-top">
    <el-row :gutter="0">
      <el-col :span="4" :offset="7">
        <div>
          <img src="https://jianhangtech.oss-cn-hangzhou.aliyuncs.com/introduce/weixing.jpg">
          <div class="foot-p-content">
            <p>微信公众号</p>
            <p>获取更多资讯</p>
          </div>
        </div>
      </el-col>
      <el-col :span="8" :offset="2">
        <div>
          <img src="https://jianhangtech.oss-cn-hangzhou.aliyuncs.com/introduce/TikTok.jpg">
          <div class="foot-p-content">
            <p>抖音号</p>
            <p>扫一扫，关注我们</p>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped lang='less'>

.extra-top {
  background-color: rgb(245, 245, 245);
  height: 150px;
  overflow: hidden;
  z-index: -20;

  padding-top: 20px;
  text-align: center;
  color: #999;
  font-size: 15px;

  img {
    z-index: -1;
    height: 120px;
    float: left;
  }

  .foot-p-content {
    width: 280px;
    z-index: -1;
    padding-top: 40px;
    font-weight: bold;
    color: black;
    font-size: 12px;
  }
}
</style>
