<template>
  <footer class="app-footer">
    <!-- 其它 -->
    <div class="extra">
      <div class="container">
        <!-- 版权信息 -->
        <div class="copyright">
          <p>
            <RouterLink to="/company">关于我们</RouterLink>
            <RouterLink to="/productadv">解决方案</RouterLink>
            <RouterLink to="/honor">企业荣誉</RouterLink>
            <RouterLink to="/product">产品中心</RouterLink>
            <RouterLink to="/contactUs">联系我们</RouterLink>
          </p>
          <p>© Copyright 2014-2024 杭州建航物流科技有限公司 <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2024106697号</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped lang='less'>
.app-footer {
  overflow: hidden;
  background-color: #f5f5f5;
  padding-top: 20px;
}

.extra {
  background-color: #333;
}

.copyright {
  height: 170px;
  padding-top: 40px;
  text-align: center;
  color: #999;
  font-size: 15px;

  a {
    color: #999;
    line-height: 1;
    padding: 0 10px;
    border-right: 1px solid #999;

    &:last-child {
      border-right: none;
    }
  }
}
</style>
