<template>
  <header class="app-header">
    <div class="container">
      <div v-if="y===0&&route.path==='/'" class="logo1">
        <RouterLink to="/"></RouterLink>
      </div>
      <div v-else class="logo2">
        <RouterLink to="/"></RouterLink>
      </div>
      <!-- 使用头部导航组件 -->
      <AppHeaderNav />
<!--      <div class="search">-->
<!--        <i class="iconfont icon-search"></i>-->
<!--        <input type="text" placeholder="全站搜索" />-->
<!--      </div>-->
    </div>
  </header>
</template>

<script>
import AppHeaderNav from './app-header-nav'
import {useRoute} from "vue-router";
import {useWindowScroll} from "@vueuse/core";
export default  {
  name: 'AppHeader',
  components: { AppHeaderNav },
  setup(){
    const route = useRoute();
    const { y } = useWindowScroll()
    return {y,route}
  }
}
</script>

<style scoped lang='less'>
.app-header {
  background: #fff;
  .container {
    display: flex;
    align-items: center;
  }
  .logo1 {
    width: 200px;
    a {
      display: block;
      height: 100px;
      width: 100%;
      text-indent: -9999px;
      position: relative;
      z-index: 1000;
      background: url('../assets/images/logo1.png')  no-repeat center 18px / contain;
    }
  }

  .logo2 {
    width: 200px;
    a {
      display: block;
      height: 100px;
      width: 100%;
      text-indent: -9999px;
      position: relative;
      z-index: 1000;
      background: url('../assets/images/logo.png')  no-repeat center 18px / contain;
    }
  }
  .search {
    width: 170px;
    height: 32px;
    position: relative;
    border-bottom: 1px solid #e7e7e7;
    line-height: 32px;
    .icon-search {
      font-size: 18px;
      margin-left: 5px;
    }
    input {
      width: 140px;
      padding-left: 5px;
      color: #666;
    }
  }
}
</style>
